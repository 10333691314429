import * as React from 'react';

export interface IEvent {
  Time: string;
  EnglishName: string;
  ChineseName: string;
  SutraUrl: string;
  Page: number;
  buttonText: string;
  active: boolean;
  locale: string;
  mobileMode: boolean;
}

/**
 *
 * Event component
 * @extends {React.PureComponent<IEvent>}
 */
export class Event extends React.PureComponent<IEvent> {
  public render(): JSX.Element | null {
    const SutraStyle = this.props.active ? 'ecomm-event active row' : 'ecomm-event row';

    let name = '';

    switch (this.props.locale) {
      case 'en-SG': {
        name = this.props.EnglishName;
        break;
      }
      case 'zh-Hans': {
        name = this.props.ChineseName;
        break;
      }
    }

    if (this.props.mobileMode) {
      return (
        <div className='ecomm-event mobile active'>
          <div className='ecomm-event-text'>
            {this.props.Time !== '' && <p className='time'>{this.props.Time}</p>}
            <p className='title'>{name}</p>
          </div>
        </div>
      );
    }

    return (
      <div className={SutraStyle}>
        <div className='ecomm-event-text col-sm-7'>
          {this.props.Time !== '' && <p className='time'>{this.props.Time}</p>}
          <p className='title'>{name}</p>
        </div>
        <div className='ecomm-event-action col-sm-5'>
          {this.props.SutraUrl !== '' && <button onClick={this._handleOnClick}>{this.props.buttonText}</button>}
        </div>
      </div>
    );
  }

  private _handleOnClick = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(this.props.SutraUrl, '', 'width=1000, height=1000');
  }
}

export default Event;